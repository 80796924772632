(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name ecs.factory:List
   *
   * @description
   *
   */
  angular
    .module('ecs')
    .factory('List', List);

  function List(_) {
    var defaultSize = 15;

    function ListBase(Item) {
      this.Item = Item;
      this.list = [];
      this.size = defaultSize;
      this.filter = {};
      this.currentPage = 1;
      this.isBusy = false;
    }

    ListBase.prototype.refreshList = function refreshList() {
      var self = this;
      if (this.isBusy) {
        return;
      }

      this.isBusy = true;
      this.Item.query(getQueryParams()).$promise
        .then(function (list) {
          setList(list);
          updatePagesAvailable(list);
        })
        .finally(function () {
          self.isBusy = false;
        });

      function getQueryParams() {
        var queryParams = {
          page: self.currentPage,
          size: self.size + 1
        };

        return angular.extend(queryParams, self.filter);
      }

      function setList(list) {
        self.list = _.take(list, self.size);
      }

      function updatePagesAvailable(list) {
        self.isPrevPageAvailable = self.currentPage > 1;
        self.isNextPageAvailable = list.length > self.size;
      }
    };

    ListBase.prototype.isLoading = function isLoading() {
      return this.isBusy;
    };

    ListBase.prototype.getList = function getList() {
      return this.list;
    };

    ListBase.prototype.getPage = function getPage() {
      return this.currentPage;
    };

    ListBase.prototype.getSize = function getSize() {
      return this.size;
    };

    ListBase.prototype.nextPage = function nextPage() {
      if (!this.hasNextPage()) {
        return;
      }
      this.currentPage++;
    };

    ListBase.prototype.previousPage = function previousPage() {
      if (!this.hasPrevoiusPage()) {
        return;
      }
      this.currentPage--;
    };

    ListBase.prototype.setSize = function setSize(size) {
      this.size = parseInt(size, 10) || defaultSize;
    };

    ListBase.prototype.setFilter = function setFilter(filter) {
      this.filter = filter;
    };

    ListBase.prototype.hasPrevoiusPage = function hasPrevoiusPage() {
      return this.isPrevPageAvailable;
    };

    ListBase.prototype.hasNextPage = function hasNextPage() {
      return this.isNextPageAvailable;
    };

    return ListBase;
  }
}());
